import React, { createContext, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { API_ERRORS, fetchJSON } from '@app/helper/services';
import { navigate } from 'gatsby';
import { URL_ASSETS } from 'gatsby-env-variables';
import HttpRequestCache from '../helper/HttpRequestCache';

const dataDefault = {
  isUserInformationLoading: false,
  isUserLoggedIn: false,
  userData: null,
  loginUser: () => {},
  registerUser: () => {},
  updateUser: () => {},
  logoutUser: () => {},
  isLoginPopupOpen: false,
  showLoginPopup: () => {},
  hideAllPopups: () => {},
  isSignupPopupOpen: false,
  showSignupPopup: () => {},
  isUserPlanFree: true,
};
const MainAppContext = createContext(dataDefault);
const httpRequestCache = HttpRequestCache.getInstance();

export const MainAppContextProvider = ({ children }) => {
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isSignupPopupOpen, setIsSignupPopupOpen] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserPlanFree, setIsUserPlanFree] = useState(true);
  const [isUserInformationLoading, setIsUserInformationLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['loggedIn', 'currentUser']);

  const showLoginPopup = () => {
    if (isUserLoggedIn) return navigate('/dashboard');
    setIsLoginPopupOpen(true);
    setIsSignupPopupOpen(false);
  };
  const showSignupPopup = () => {
    if (isUserLoggedIn) return navigate('/dashboard');
    setIsLoginPopupOpen(false);
    setIsSignupPopupOpen(true);
  };
  const hideAllPopups = () => {
    setIsLoginPopupOpen(false);
    setIsSignupPopupOpen(false);
  };

  const saveLocalUserData = (user) => {
    const data = {
      ...user,
      avatar: user?.avatar ? `${URL_ASSETS}${user?.avatar}?v=${Math.floor(Math.random() * 1000)}` : null,
    };

    console.log('saveLocalUserData: data', data);
    setUserData(data);
    setIsUserLoggedIn(true);
    setIsUserInformationLoading(false);
    setIsUserPlanFree(data?.payment_type !== 'Monthly' && data?.payment_type !== 'Yearly');
    setCookie('currentUser', user.id, { path: '/' });
  };

  const cleanLocalUserData = async () => {
    setUserData(null);
    setIsUserLoggedIn(false);
    removeCookie('currentUser', '', { path: '/' });
    removeCookie('kiran_site_session', '', { path: '/' });
    removeCookie('XSRF-TOKEN', '', { path: '/' });
  };

  const logoutUser = async () => {
    await cleanLocalUserData();
  };

  const loginUser = async (email, password) => {
    try {
      setIsUserInformationLoading(true);
      const res = await fetchJSON('login', { email, password }, 'POST', false); // cache false
      saveLocalUserData(res?.data);
      hideAllPopups();
      return res?.data;
    } catch (error) {
      console.log('loginUser > error', error);
      cleanLocalUserData();
      throw error;
    }
  };

  const updateUser = async (data) => {
    try {
      const nData = { ...data, _method: 'PUT' };
      const res = await fetchJSON(`user/${userData.id}`, nData, 'POST', false); // cache false
      saveLocalUserData(res?.data);
      return res?.data;
    } catch (error) {
      console.log('updateUser > error', error);
      throw error;
    }
  };

  const registerUser = async (name, display_name, email, password, password_confirmation) => {
    try {
      const res = await fetchJSON('register', { name, display_name, email, password, password_confirmation }, 'POST', false); // cache false
      saveLocalUserData(res?.data);
      hideAllPopups();
      return res?.data;
    } catch (error) {
      console.log('registerUser > error', error);
      cleanLocalUserData();
      throw error;
    }
  };

  const checkLocalUserData = async () => {
    console.log('checkLocalUserData init');
    setIsUserInformationLoading(true);

    try {
      if (cookies?.currentUser && typeof cookies?.currentUser === 'string') {
        const res = await fetchJSON(`user/${cookies.currentUser}`);
        console.log('0. checkLocalUserData: user is logged in!! data', res);
        saveLocalUserData(res?.data);
      } else {
        console.log('1. checkLocalUserData: user is not logged in!!');
        cleanLocalUserData();
      }
    } catch (error) {
      console.log('2. checkLocalUserData: local user data with error!!', error);
      cleanLocalUserData();

      if (error?.status === API_ERRORS.INVALID_USER) {
        console.log('3. checkLocalUserData: forced logout from api!!');
        navigate('/logout/?redirect=/login&error=401');
      }
    } finally {
      setIsUserInformationLoading(false);
    }
  };

  useEffect(() => {
    checkLocalUserData();
  }, []);

  return (
    <MainAppContext.Provider
      value={{
        isUserInformationLoading,
        isUserLoggedIn,
        userData,
        loginUser,
        registerUser,
        updateUser,
        logoutUser,
        isLoginPopupOpen,
        showLoginPopup,
        hideAllPopups,
        isSignupPopupOpen,
        showSignupPopup,
        isUserPlanFree,
      }}>
      {children}
    </MainAppContext.Provider>
  );
};

export const useNavigationPopups = () => {
  const { isLoginPopupOpen, showLoginPopup, hideAllPopups, isSignupPopupOpen, showSignupPopup } = useContext(MainAppContext);
  return { isLoginPopupOpen, showLoginPopup, hideAllPopups, isSignupPopupOpen, showSignupPopup };
};

export const useAuth = () => {
  const {
    isUserLoggedIn,
    userData,
    loginUser,
    updateUser,
    logoutUser,
    registerUser,
    isUserInformationLoading,
    isUserPlanFree,
    setIsUserPlanFree,
  } = useContext(MainAppContext);
  return {
    isUserLoggedIn,
    userData,
    loginUser,
    updateUser,
    logoutUser,
    registerUser,
    isUserInformationLoading,
    isUserPlanFree,
  };
};
